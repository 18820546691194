import _ from '@/utils/lodash'

export default {
  selectGruposPlantilla (Vue, idplantillaArticulo) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.plantilla_grupo_articulo)
      .innerJoin(
        tables.tsubsis,
        tables.plantilla_grupo_articulo.idtsubsis.eq(tables.tsubsis.idtsubsis)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.plantilla_grupo_articulo.estado.gt(0),
          tables.plantilla_grupo_articulo.idplantilla_articulo.eq(idplantillaArticulo),
        )
      )
      .orderBy(tables.plantilla_grupo_articulo.orden)
      .exec()
  },
  async selectCodigosSubsis(Vue, idsistema) {
    const tables = Vue.$offline.db.tables
    const subsis = await Vue.$offline.db
      .select()
      .from(tables.subsis)
      .where(
        Vue.$offline.db.op.and(
          tables.subsis.estado.gt(0),
          tables.subsis.idsistema.eq(idsistema),
        )
      )
      .exec()
    return _.map(subsis, 'codigo')
  },
}
